import React, { useState, useEffect } from "react";
import { axios } from "../../../request/settings";
import { useUxHandler } from "../../../context/uxHandlerContext";
import useCategories from "../../../hooks/useCategories";
import { createOfferFast } from "../../../request/home/offers";
import { useUser } from "../../../context/userContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextEditor from "@components/shared/TextEditor";
import SelectCategories1 from "./SelectCategories1";
import SelectCategories2 from "./SelectCategories2";
import styled from '@emotion/styled';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Fieldset = styled.fieldset`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background: white;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  legend {
    color: #666;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0 0.5rem;
  }

  ${props => props.variant === 'shipping' && `
    border-color: #3ead47;
  `}
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    color: #666;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  
  &:focus {
    border-color: #3ead47;
    box-shadow: 0 0 0 2px rgba(62, 173, 71, 0.1);
    outline: none;
  }

  ${props => props.hasError && `
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.1);
    }
  `}

  &:disabled {
    background: #f8f9fa;
    cursor: not-allowed;
  }
`;

const AlertMessage = styled.div`
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  font-size: 1.2rem;
  
  ${props => props.variant === 'warning' && `
    background: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
  `}
  
  ${props => props.variant === 'info' && `
    background: #e8f4ff;
    color: #0066cc;
    border: 1px solid rgba(0, 102, 204, 0.1);
  `}
`;

const ImagePreview = styled.img`
  max-height: 300px;
  border-radius: 10px;
  margin: 1rem 0;
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  justify-content: center;
`;

const Button = styled.button`
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  
  ${props => props.variant === 'primary' && `
    background: linear-gradient(135deg, #3ead47, #70cd38);
    color: white;
    
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(62, 173, 71, 0.2);
    }
  `}
  
  ${props => props.variant === 'outline' && `
    background: white;
    color: #3ead47;
    border: 1px solid #3ead47;
    
    &:hover {
      background: #f8f9fa;
    }
  `}

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: #fff5f5;
  border-radius: 4px;
`;

const EditorContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  
  &:focus-within {
    border-color: #3ead47;
    box-shadow: 0 0 0 2px rgba(62, 173, 71, 0.1);
  }
  
  .ql-toolbar {
    border: none;
    border-bottom: 1px solid #e0e0e0;
    background: #f8f9fa;
  }
  
  .ql-container {
    border: none;
    background: white;
    min-height: 200px;
  }
`;

const StyledSwitch = styled(FormControlLabel)`
  && {
    margin: 1rem 0;
    .MuiSwitch-root {
      .MuiSwitch-track {
        background-color: #e0e0e0;
      }
      .MuiSwitch-thumb {
        background-color: white;
      }
      .Mui-checked + .MuiSwitch-track {
        background-color: #3ead47 !important;
      }
      .Mui-checked .MuiSwitch-thumb {
        background-color: #70cd38;
      }
    }
  }
`;

const LoadingSpinner = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const FormOfferFast = ({ titleOffer, urlOffer, imageOffer, handleClose }) => {
  const { user } = useUser();
  const [fileUrl, setFileUrl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { setAlert } = useUxHandler();
  const randomRanking = Math.floor(Math.random() * (35 - 25 + 1)) + 25;
  const idShopDefault = process.env.NEXT_PUBLIC_SHOP_DEFAULT;
  const idCategoryDefault = process.env.NEXT_PUBLIC_CATEGORY_DEFAULT;
  const enableCreation = process.env.NEXT_PUBLIC_CREATE_OFFER_FAST === 'true';

  const [descriptionCounter, setDescriptionCounter] = useState(0);
  const [descriptionText, setDescriptionText] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [viewSend, setViewSend] = useState(false);
  const [viewSend2, setViewSend2] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const categories = useCategories();

  const [categorySelector, setCategorySelector] = useState(1);

  const [errors, setErrors] = useState({
    url: false,
    title: false,
    image: false,
    shop_id: false,
    category_ids: false,
    dates: false
  });

  const defaultData = {
    url: urlOffer,
    title: titleOffer,
    description: "",
    actual_price: "",
    normal_price: "",
    shipping_cost: "",
    init_date: "",
    end_date: "",
    code: "",
    image: "",
    user_aproved_id: user.id,
    aproved: true,
    applies_shipping: false,
    shop_id: parseInt(idShopDefault),
    ranking: parseInt(randomRanking),
    category_ids: [parseInt(idCategoryDefault)],
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setData({
      ...data,
      category_ids: selectedCategory.map((e) => e.value),
    });
  }, [selectedCategory]);

  useEffect(() => {
    const today = new Date();
    const formattedToday = today.toISOString().split("T")[0];

    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 8);
    const formattedFutureDate = futureDate.toISOString().split("T")[0];

    setData({
      ...defaultData,
      url: urlOffer,
      title: titleOffer,
      init_date: formattedToday,
      end_date: formattedFutureDate,
      image: imageOffer,
    });
  }, []);

  useEffect(() => {
    if (imageOffer) {
      const imageUrl = URL.createObjectURL(imageOffer);
      setFileUrl(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    }
  }, [imageOffer]);

  useEffect(() => {
    if (data.shipping_cost === "" || data.shipping_cost === -1 || data.shipping_cost < 0) {
      setViewSend2(false);
    } else {
      setViewSend2(true);
    }
  }, [data.shipping_cost]);

  const getData = (e) => {
    const { name, value } = e.target;
    
    if (name === 'init_date') {
      if (data.end_date && value > data.end_date) {
        setData({
          ...data,
          init_date: value,
          end_date: value
        });
        return;
      }
    }
    
    if (name === 'end_date' && value < data.init_date) {
      return;
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const getCheckSearch = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked,
    });
    setViewSend((prevState) => !prevState);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    if (data.url.length === 0) {
      setErrors({...errors, url: true});
      return;
    }
    if (data.title.length === 0) {
      setErrors({...errors, title: true});
      return;
    }
    if (!data.image) {
      setErrors({...errors, image: true});
      return;
    }
    if(!data.shop_id || !Number.isInteger(Number(data.shop_id))) {
      setAlert({ isActive: true, title: '¡Oops error con la tienda!', message: 'No podemos crear tu oferta debido a que no hay una tienda asignada, verifica tus variables de entorno.', type: 'warning' });
      return;
    }
    if (
      !Array.isArray(data.category_ids) || 
      data.category_ids.length === 0 ||
      data.category_ids.some((id) => !Number.isInteger(id))
    ) {
      setAlert({
        isActive: true,
        title: '¡Oops error con la categoría!',
        message: 'No podemos crear tu oferta debido a que no hay una categoría asignada, verifica tus variables de entorno.',
        type: 'warning',
      });
      return;
    }
    if (data.end_date < data.init_date) {
      setErrors(prev => ({...prev, dates: true}));
      return;
    }
    setDisabled(true);
    const record = { ...data, description: descriptionContent };
    await createOfferFast(record);
    setAlert({ isActive: true, title: '¡Muy bien!', message: 'La oferta fue creada con éxito para edición y aprobación.', type: 'success' });
    setDisabled(false);
    handleClose();
  };

  return (
    <Container>
      <Form onSubmit={sendForm}>
        <Fieldset>
          <legend>Información general</legend>
          <FormGroup>
            <label htmlFor="url">Sitio web</label>
            <Input
              type="url"
              id="url"
              value={urlOffer}
              placeholder="Ingresa la dirección web de la oferta"
              name="url"
              disabled
              hasError={errors.url}
            />
            {errors.url && (
              <ErrorMessage>La URL no puede ir vacía</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup>
            <label htmlFor="title">Nombre de la oferta</label>
            <Input
              type="text"
              id="title"
              value={data.title || ""}
              placeholder="Escribe el nombre de la oferta"
              name="title"
              onChange={getData}
              hasError={errors.title}
            />
            {errors.title && (
              <ErrorMessage>El nombre no puede ir vacío</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup>
            <label htmlFor="description">Descripción de la oferta</label>
            <EditorContainer>
              <TextEditor
                text={descriptionContent}
                onTextChange={(text) => {
                  setDescriptionText(text);
                  setDescriptionCounter(text.length);
                }}
                onContentChange={(content) => {
                  setDescriptionContent(JSON.stringify(content));
                }}
              />
            </EditorContainer>
          </FormGroup>

          {fileUrl && (
            <FormGroup>
              <label>Vista previa de la imagen</label>
              <ImagePreview src={fileUrl} alt="Vista previa" />
            </FormGroup>
          )}
        </Fieldset>

        <Fieldset>
          <legend>Detalles de la oferta</legend>
          <GridContainer>
            <FormGroup>
              <label htmlFor="actual_price">Precio Actual</label>
              <Input
                type="number"
                id="actual_price"
                value={data.actual_price}
                placeholder="Escribe el precio actual"
                name="actual_price"
                onChange={getData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="normal_price">Precio Normal</label>
              <Input
                type="number"
                id="normal_price"
                value={data.normal_price}
                placeholder="Escribe el precio normal"
                name="normal_price"
                onChange={getData}
              />
            </FormGroup>
          </GridContainer>
        </Fieldset>

        <Fieldset variant="shipping">
          <legend>Envíos</legend>
          <StyledSwitch
            control={
              <Switch
                checked={data.applies_shipping || false}
                onChange={getCheckSearch}
                color="primary"
                name="applies_shipping"
                disabled={viewSend2}
              />
            }
            label="¿Aplica envío elegible?"
          />

          <FormGroup>
            <label htmlFor="shipping_cost">Costo de envío</label>
            <Input
              type="number"
              id="shipping_cost"
              value={data.shipping_cost}
              placeholder="Escribe el costo de envío"
              name="shipping_cost"
              onChange={getData}
              min="-1"
              disabled={viewSend}
            />
            <AlertMessage variant="info">
              <strong>Valores para envíos:</strong>
              <ul style={{ marginTop: '0.5rem', paddingLeft: '1.5rem' }}>
                <li>Valor (-1 o vacío) = No se verá etiqueta</li>
                <li>Valor 0 = Etiqueta Envío Gratis</li>
                <li>Valor {'>'} 0 = Etiqueta Con costo de envío</li>
              </ul>
            </AlertMessage>
          </FormGroup>
        </Fieldset>

        <Fieldset>
          <legend>Categorías de la oferta</legend>
          <ButtonGroup>
            <Button
              type="button"
              variant={categorySelector === 1 ? 'primary' : 'outline'}
              onClick={() => setCategorySelector(1)}
            >
              Selector con Árbol
            </Button>
            <Button
              type="button"
              variant={categorySelector === 2 ? 'primary' : 'outline'}
              onClick={() => setCategorySelector(2)}
            >
              Selector con Búsqueda
            </Button>
          </ButtonGroup>

          {categorySelector === 1 ? (
            <SelectCategories1 
              categories={categories}
              initialSelectedCategories={selectedCategory}
              onCategoryChange={(selected) => {
                setSelectedCategory(selected);
                setData({
                  ...data,
                  category_ids: selected.map((e) => e.value),
                });
              }}
            />
          ) : (
            <SelectCategories2 
              categories={categories}
              initialSelectedCategories={selectedCategory}
              onCategoryChange={(selected) => {
                setSelectedCategory(selected);
                setData({
                  ...data,
                  category_ids: selected.map((e) => e.value),
                });
              }}
            />
          )}
        </Fieldset>

        <Fieldset>
          <legend>Fechas de la oferta</legend>
          <GridContainer>
            <FormGroup>
              <label htmlFor="init_date">Fecha de Inicio</label>
              <Input
                type="date"
                id="init_date"
                name="init_date"
                value={data.init_date}
                onChange={getData}
                min={new Date().toISOString().split('T')[0]}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="end_date">Fecha de Fin</label>
              <Input
                type="date"
                id="end_date"
                name="end_date"
                value={data.end_date}
                onChange={getData}
                min={data.init_date}
              />
            </FormGroup>
          </GridContainer>
          {errors.dates && (
            <ErrorMessage>
              La fecha final no puede ser anterior a la fecha inicial
            </ErrorMessage>
          )}
        </Fieldset>

        <Fieldset>
          <legend>Código de descuento</legend>
          <FormGroup>
            <label htmlFor="code">Código</label>
            <Input
              type="text"
              id="code"
              placeholder="Ingresa el código de descuento"
              name="code"
              onChange={getData}
            />
          </FormGroup>
        </Fieldset>

        <Fieldset>
          <legend>Ranking</legend>
          <FormGroup>
            <Input
              type="number"
              id="ranking"
              name="ranking"
              defaultValue={randomRanking}
              disabled
            />
          </FormGroup>
        </Fieldset>

        <ButtonGroup>
          {disabled ? (
            <Button variant="primary" disabled>
              <LoadingSpinner className="spinner-border spinner-border-sm" />
              Cargando...
            </Button>
          ) : (
            <Button
              type="submit"
              variant="primary"
              disabled={!enableCreation}
            >
              Crear Oferta
            </Button>
          )}
        </ButtonGroup>
      </Form>
    </Container>
  );
};

export default FormOfferFast;
