import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { axios } from "../../../request/settings";
import Image from "next/image";
import styled from '@emotion/styled';

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: white;
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  outline: none;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 400px;
  }
`;

const ModalTitle = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
`;

const TableContainer = styled.div`
  height: 280px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    &:hover {
      background: #999;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  th {
    color: #666;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 10px;
    text-align: left;
    border-bottom: 2px solid #f0f0f0;
  }

  td {
    padding: 12px 10px;
    font-size: 0.9rem;
    color: #333;
    background: #f8f9fa;
    
    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    
    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  tr {
    transition: all 0.2s ease;
    
    &:hover td {
      background: #f0f0f0;
      transform: translateX(5px);
    }
  }
`;

const EmptyMessage = styled.section`
  background: #fff5f5;
  color: #e53e3e;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 40px;
  position: relative;
  background: white;
  border-radius: 6px;
  padding: 3px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
`;

const MenuOffers = ({ open, setOpen }) => {
  const [data, setData] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const fetchOffers = async () => {
    const { data } = await axios.get(`/offers/pending`);
    setData(data);
  };

  useEffect(() => {
    fetchOffers();
    return [];
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-ofertas-pendientes"
      aria-describedby="modal-listado-ofertas-pendientes"
    >
      <ModalContainer>
        <ModalTitle>Ofertas para publicar</ModalTitle>
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <th>Imagen</th>
                <th>Oferta</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((date) => (
                <tr key={`alert-offer-${date.id}`}>
                  <td>
                    <ImageContainer>
                      <Image
                        src={date.image_url || ""}
                        alt={date.title}
                        width={50}
                        height={30}
                        quality={10}
                        objectFit="contain"
                        placeholder="blur"
                        blurDataURL="/statics/img/logo.jpeg"
                      />
                    </ImageContainer>
                  </td>
                  <td>{date.title}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          {data && data.length === 0 && (
            <EmptyMessage>
              No hay ofertas que mostrar.
            </EmptyMessage>
          )}
        </TableContainer>
      </ModalContainer>
    </Modal>
  );
};

export default MenuOffers;
