import React, { useState, useEffect } from "react";
import MultiSelect from "react-multi-select-component";
import styled from "styled-components";
import { BoxMultiselect as BaseBoxMultiselect } from "../../admin_ui/Form";
import { axios } from "../../../request/settings";

const overrideStrings = {
  selectSomeItems: "Selecciona",
  allItemsAreSelected: "Todos los elementos están seleccionados..",
  selectAll: "Seleccionar todo",
  search: "Buscar",
  clearSearch: "Borrar búsqueda",
};

const StyledBoxMultiselect = styled(BaseBoxMultiselect)`
  .dropdown-content .item-renderer {
    padding: 6px 10px;
    transition: all 0.2s ease;
  }

  .dropdown-content .item-renderer:not(:has([data-parent-id])) {
    font-weight: bold;
    border-bottom: 1px solid #eee;
  }

  .dropdown-content .item-renderer:has([data-parent-id]) {
    font-size: 0.95em;
    color: #666;
    background-color: #f9f9f9;
  }

  /* Estilos para diferentes niveles de indentación */
  .dropdown-content .item-renderer[data-level="0"] {
    padding-left: 10px;
    font-weight: bold;
  }

  .dropdown-content .item-renderer[data-level="1"] {
    padding-left: 35px;
    background-color: #f9f9f9;
  }

  .dropdown-content .item-renderer[data-level="2"] {
    padding-left: 60px;
    background-color: #f5f5f5;
  }

  .dropdown-content .item-renderer[data-level="3"] {
    padding-left: 85px;
    background-color: #f0f0f0;
  }

  .dropdown-content .item-renderer input[type="checkbox"] {
    margin-right: 8px;
  }

  .dropdown-content .item-renderer:hover {
    background-color: #e9ecef;
  }
`;

const SelectCategories1 = ({ categories, initialSelectedCategories = [], onCategoryChange }) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const optionsCategory = categories
    .map((e) => ({ label: e.name, value: e.id }))
    .filter((elm) => elm.label !== "cupones");

  useEffect(() => {
    const loadInitialSubcategories = async () => {
      if (initialSelectedCategories.length > 0) {
        setSelectedCategory(initialSelectedCategories);
        
        const processedIds = new Set();
        for (const category of initialSelectedCategories) {
          const newSubCategories = await fetchSubCategories(category.value, 1, processedIds);
          setSubCategories(prev => [...prev, ...newSubCategories]);
        }
      }
    };

    loadInitialSubcategories();
  }, [initialSelectedCategories]);

  const fetchSubCategories = async (categoryId, level = 1, processedIds = new Set()) => {
    try {
      if (processedIds.has(categoryId)) {
        return [];
      }
      processedIds.add(categoryId);

      const response = await axios.get(`/categories/sub_categories?id=${categoryId}`);
      return response.data.map(cat => ({
        label: `${'↳'.repeat(level)} ${cat.name}`,
        value: cat.id,
        parentId: categoryId,
        level: level,
        hasChildren: true
      }));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const handleCategoryChange = async (selected) => {
    const lastChanged = selected.length > selectedCategory.length
      ? selected.find(sel => !selectedCategory.find(prev => prev.value === sel.value))
      : selectedCategory.find(prev => !selected.find(sel => sel.value === prev.value));

    if (lastChanged) {
      if (selected.length > selectedCategory.length) {
        const newSubCategories = await fetchSubCategories(lastChanged.value, 1);
        setSubCategories(prev => [...prev, ...newSubCategories]);
        setSelectedCategory(selected);
        onCategoryChange(selected);
      } else {
        const categoriesToRemove = new Set([lastChanged.value]);
        
        const findChildCategories = (parentId) => {
          const directChildren = subCategories.filter(sub => sub.parentId === parentId);
          
          directChildren.forEach(child => {
            categoriesToRemove.add(child.value);
            findChildCategories(child.value);
          });
        };

        findChildCategories(lastChanged.value);

        const newSelected = selected.filter(cat => 
          !categoriesToRemove.has(cat.value)
        );

        setSelectedCategory(newSelected);
        
        setSubCategories(prev => 
          prev.filter(sub => !categoriesToRemove.has(sub.value))
        );

        onCategoryChange(newSelected);
      }
    } else {
      setSelectedCategory(selected);
      onCategoryChange(selected);
    }
  };

  const sortOptionsWithSubcategories = (categories, subcategories) => {
    const sortedOptions = [];
    
    const addCategoryWithChildren = (category, level = 0) => {
      const categoryWithLevel = {
        ...category,
        label: level > 0 
          ? `${'↳'.repeat(level)} ${category.label}`
          : category.label,
        level: level
      };
      sortedOptions.push(categoryWithLevel);
      
      const children = subcategories
        .filter(sub => sub.parentId === category.value)
        .sort((a, b) => a.label.localeCompare(b.label));

      children.forEach(child => {
        addCategoryWithChildren(child, level + 1);
      });
    };

    categories.forEach(category => {
      addCategoryWithChildren(category);
    });
    
    return sortedOptions;
  };

  return (
    <StyledBoxMultiselect className="form-group">
      <label>
        <strong>Categorías seleccionadas:</strong>
      </label>
      <div className="selected-categories mb-3">
        <div className="mt-2">
          {selectedCategory.map((category) => (
            <span
              key={category.value}
              className="badge bg-primary me-2 mb-2"
              style={{ fontSize: '0.9em' }}
            >
              {category.label}
            </span>
          ))}
        </div>
      </div>
      <MultiSelect
        options={sortOptionsWithSubcategories(optionsCategory, subCategories)}
        value={selectedCategory}
        onChange={handleCategoryChange}
        labelledBy={"Selecciona las Categorías de la oferta"}
        className="multiselects"
        overrideStrings={overrideStrings}
        itemRenderer={({ checked, option, onClick, disabled }) => (
          <div
            className="item-renderer"
            data-level={option.level || 0}
            data-parent-id={option.parentId}
            onClick={onClick}
          >
            <input
              type="checkbox"
              onChange={() => {}}
              checked={checked}
              disabled={disabled}
              className="mr-2"
            />
            <span>
              {option.label}
              {option.hasChildren && !checked && " ▸"}
            </span>
          </div>
        )}
      />
    </StyledBoxMultiselect>
  );
};

export default SelectCategories1;
