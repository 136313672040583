import Link from 'next/link';
import Image from 'next/image';
import styled from '@emotion/styled';

const getGradientByTheme = (theme) => {
  const themes = {
    blue: 'linear-gradient(135deg, #4A90E2, #357ABD)',
    green: 'linear-gradient(135deg, #2ECC71, #27AE60)',
    purple: 'linear-gradient(135deg, #9B59B6, #8E44AD)',
    orange: 'linear-gradient(135deg, #E67E22, #D35400)',
    red: 'linear-gradient(135deg, #E74C3C, #C0392B)',
    teal: 'linear-gradient(135deg, #1ABC9C, #16A085)',
    default: 'linear-gradient(135deg, #222, #333)'
  };
  return themes[theme] || themes.default;
};

const Card = styled.div`
  height: 350px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: none;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  height: 130px;
  background: ${props => getGradientByTheme(props.theme)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 15px;
`;

const CardTitle = styled.h2`
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
`;

const CardBody = styled.div`
  padding: 25px 20px;
  position: relative;
  height: calc(100% - 130px);
`;

const Table = styled.table`
  width: 100%;
  margin-top: 10px;
  
  th, td {
    padding: 8px 4px;
    border: none;
    font-size: 0.9rem;
  }
  
  th {
    color: #666;
    font-weight: 500;
  }
  
  td {
    text-align: right;
    font-weight: 600;
    color: #333;
  }

  tr {
    border-bottom: 1px solid #f0f0f0;
    
    &:last-child {
      border-bottom: none;
    }
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  flex: 1;
  border: none;
  
  &.btn-secondary {
    background: #f0f0f0;
    color: #333;
    
    &:hover {
      background: #e0e0e0;
      transform: translateY(-2px);
    }
  }
  
  &.btn-dark {
    background: linear-gradient(135deg, #222, #333);
    color: white;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
`;

const CardDate = ({ 
  imageCard = "editar", 
  nameCard = "Tarjeta", 
  date = [], 
  isSuperAdmin, 
  accessMenus, 
  user, 
  button1 = [], 
  button2 = [], 
  accessButton1, 
  accessButton2,
  theme = 'default'
}) => {
  
  const verifiedMenu = (name, id) => {
    const findMenu = accessMenus.find((item) => item.name === name);
    if (findMenu && findMenu.access && findMenu.access.includes(id.toString())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
      <Card>
        <CardHeader theme={theme}>
          <CardTitle>{nameCard}</CardTitle>
          <Image 
            src={`/statics/img/icon_menu/${imageCard}.png`} 
            width={40} 
            height={40} 
            alt={nameCard}
            style={{ filter: 'invert(1)'}} 
          />
        </CardHeader>
        <CardBody>
          <Table>
            <tbody>
              {date.slice(0, 4).map((item, index) => (
                item && (
                  <tr key={index}>
                    <th>{item[0]}</th>
                    <td><b>{item[1]}</b></td>
                  </tr>
                )
              ))}
            </tbody>
          </Table>
          <ButtonContainer>
            {(isSuperAdmin || (user && verifiedMenu(accessButton1, user.id))) && 
              button1[0] === "view" && (
                <Link href={button1[1] ? `/admin/${button1[1]}` : '/'}>
                  <StyledButton className="btn btn-secondary">
                    {button1[2] || ""}
                  </StyledButton>
                </Link>
              )
            }
            {(isSuperAdmin || (user && verifiedMenu(accessButton2, user.id))) && 
              button2[0] === "view" && (
                <Link href={button2[1] ? `/admin/${button2[1]}` : '/'}>
                  <StyledButton className="btn btn-dark">
                    {button2[2] || ""}
                  </StyledButton>
                </Link>
              )
            }
          </ButtonContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardDate;
