import Image from 'next/image';
import CardDate from './principal/CardDate';

const Principal = ({ user, datesIndex, accessMenus }) => {
  const isSuperAdmin = user && user.role === "superadmin";

  const verifiedMenu = (name, id) => {
    const findMenu = accessMenus.find((item) => item.name === name);
    if (findMenu && findMenu.access && findMenu.access.includes(id.toString())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {isSuperAdmin || verifiedMenu("principal", user.id) ? (
        <div className="container p-5">
          <div className="row">
            <div className="col">
              <h1 className="display-5">Resumen General</h1>
            </div>
          </div>
          <div className="row">
            <CardDate 
              imageCard='etiqueta-de-precio'
              nameCard='Ofertas'
              date = 
                {[
                  ["Ofertas Por Publicar", datesIndex.offers_pending],
                  ["Ofertas Publicadas", datesIndex.offers_published],
                  ["Ofertas Vencidas", datesIndex.offers_end_date],
                  ["Ofertas Eliminadas", datesIndex.offers_deleted]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button1={["view", "create_offer", "Crear Oferta"]}
              button2={["view", "offers", "Ir a Ofertas"]}
              accessButton1='crear oferta'
              accessButton2='ofertas'
              theme="blue"
            />
            <CardDate 
              imageCard='cupon'
              nameCard='Cupones'
              date = 
                {[
                  ["Cupones Por Publicar", datesIndex.coupons_pending],
                  ["Cupones Publicados", datesIndex.coupons_published],
                  ["Cupones Vencidos", datesIndex.coupons_end_date],
                  ["Cupones Eliminados", datesIndex.coupons_deleted]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button1={["view", "create_coupon", "Crear Cupon"]}
              button2={["view", "coupons", "Ir a Cupones"]}
              accessButton1='crear cupon'
              accessButton2='cupones'
              theme="green"
            />
            <CardDate 
              imageCard='conversacion'
              nameCard='Comentarios'
              date = 
                {[
                  ["Comentarios Por Publicar", datesIndex.comment_pending],
                  ["Comentarios Publicados", datesIndex.comment_aproved]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button2={["view", "comments", "Ir a Comentarios"]}
              accessButton2='comentarios'
              theme="purple"
            />
            <CardDate 
              imageCard='categorias'
              nameCard='Categorías'
              date = 
                {[
                  ["Categorías Publicadas", datesIndex.category_active]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button1={["view", "category", "Crear Categoría"]}
              accessButton1='categorias'
              theme="orange"
            />
            <CardDate 
              imageCard='carro'
              nameCard='Tiendas'
              date = 
                {[
                  ["Tiendas Publicadas", datesIndex.shop_active]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button1={["view", "shops", "Crear Tienda"]}
              accessButton1='tiendas'
              theme="red"
            />
            <CardDate 
              imageCard='usuario'
              nameCard='Usuarios'
              date = 
                {[
                  ["Usuarios Registrados", datesIndex.user_register]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button2={["view", "users", "Ir a Usuarios"]}
              accessButton2='usuarios'
              theme="teal"
            />
            <CardDate 
              imageCard='blog'
              nameCard='Blog'
              date = 
                {[
                  ["Blogs Publicados", datesIndex.blog_published],
                  ["Blogs por Publicar", datesIndex.blog_pending],
                  ["Blogs Eliminados", datesIndex.blog_delete],
                  ["Categorías de Blogs", datesIndex.categories_posts]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button1={["view", "blog", "Crear Blog"]}
              accessButton1='blog'
              theme="blue"
            />
            <CardDate 
              imageCard='chat'
              nameCard='Foro'
              date = 
                {[
                  ["Temas Publicados", datesIndex.forum_total],
                  ["Temas Aprobados", datesIndex.forum_approved],
                  ["Comentarios Publicados", datesIndex.forum_comments]
                ]}  
              isSuperAdmin={isSuperAdmin}
              accessMenus={accessMenus}
              user={user}
              viewButtons={true}
              button2={["view", "forum", "Ir al Foro"]}
              accessButton2='forum'
            />
          </div>
        </div>
      ) : (
        <div className="container text-center mt-5 pt-5">
          <div className="text-center">
            <Image width={200} height={200} className="rounded" src="/statics/img/512x512.png" alt="Profile" style={{objectFit: 'cover'}} />
          </div>
          <h1 className="h1 mt-5">
            Bienvenido al dashboard de Ofertu, si necesitas mas accesos comunícate con un administrador para darte
            permisos.
          </h1>
        </div>
      )}
    </div>
  );
};

export default Principal;
