import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import Slide from '@material-ui/core/Slide';
import { useUxHandler } from '../context/uxHandlerContext';
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    position: fixed;
    top: 62px;
    right: -26px;
    width: 350px;
    background: white;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    overflow: hidden;
  }
`;

const AlertContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 200px;
`;

const StyledAlert = styled(Alert)`
  height: 100%;
  padding: 1.8rem !important;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .MuiAlert-icon {
    padding: 0;
    font-size: 28px !important;
    margin-right: 16px;
  }

  .MuiAlert-message {
    padding: 0;
  }

  &.MuiAlert-standardSuccess {
    background-color: #edf7ed;
    color: #1e4620;
  }

  &.MuiAlert-standardError {
    background-color: #fdeded;
    color: #5f2120;
  }

  &.MuiAlert-standardWarning {
    background-color: #fff4e5;
    color: #663c00;
  }

  &.MuiAlert-standardInfo {
    background-color: #e5f6fd;
    color: #014361;
  }
`;

const Title = styled(AlertTitle)`
  &.MuiAlertTitle-root {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    margin-bottom: 4px !important;
  }
`;

const Message = styled.span`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MessageAlert = () => {
  const { alert, setAlert } = useUxHandler();

  const handleClose = () => {
    setAlert({ ...alert, isActive: false });
  };

  return (
    <StyledDialog
      open={alert.isActive}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AlertContainer>
        <StyledAlert severity={alert.type}>
          <Title>{alert.title}</Title>
          <Message>{alert.message}</Message>
        </StyledAlert>
      </AlertContainer>
    </StyledDialog>
  );
};

export default MessageAlert;
