import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import FormOfferFast from "./offers/FormOfferFast";
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 15px;
    overflow: hidden;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  background: white;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e0e0e0;

  h2 {
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    margin: 0;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 2rem;
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    &:hover {
      background: #999;
    }
  }
`;

const ModalOfferFast = ({ open, handleClose, titleOffer, urlOffer, imageOffer }) => {
  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledDialogTitle>
        <h2>Crear Oferta Rápida</h2>
      </StyledDialogTitle>
      <StyledDialogContent>
        <FormOfferFast
          titleOffer={titleOffer}
          urlOffer={urlOffer}
          imageOffer={imageOffer}
          handleClose={handleClose}
        />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ModalOfferFast;
