import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import styled from '@emotion/styled';
import { axios } from "../../request/settings";

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: white;
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  outline: none;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 400px;
  }
`;

const ModalTitle = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 1rem;
    background: #f0f0f0;
    padding: 4px 12px;
    border-radius: 20px;
    color: #666;
  }
`;

const AlertsContainer = styled.div`
  height: 280px;
  overflow-y: auto;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    &:hover {
      background: #999;
    }
  }
`;

const AlertCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 8px 0;
    font-size: 0.9rem;
    color: #444;

    &.title {
      font-weight: 600;
      color: #333;
    }
  }

  .label {
    color: #666;
    font-weight: 500;
  }
`;

const StatusBadge = styled.div`
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  
  ${props => {
    switch (props.status) {
      case 'expired':
        return `
          background: #FEE2E2;
          color: #DC2626;
        `;
      case 'warning':
        return `
          background: #FEF3C7;
          color: #D97706;
        `;
      default:
        return `
          background: #DCFCE7;
          color: #16A34A;
        `;
    }
  }}
`;

const EmptyMessage = styled.section`
  background: #fff5f5;
  color: #e53e3e;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
`;

const ModalAlert = ({ open, setOpen, setCountAlerts }) => {
  const [data, setData] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const getStatusInfo = (dateAString) => {
    const currentDate = new Date();
    const dateReceived = new Date(dateAString);
    const difference = dateReceived - currentDate;
    const dateDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

    if (dateDifference < 0) {
      return {
        message: "El cupón ya está vencido",
        status: "expired"
      };
    } else if (dateDifference === 0) {
      return {
        message: "Hoy es el último día",
        status: "warning"
      };
    } else {
      return {
        message: `Faltan ${dateDifference} días para que se venza el cupón`,
        status: "active"
      };
    }
  };

  useEffect(() => {
    const getOffer = async () => {
      const response = await axios.get(`/coupons/alerts_for_coupons`);
      setData(response.data);
      setCountAlerts(response.data.length);
    }
    getOffer();
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-alertas-cupones"
      aria-describedby="modal-listado-alertas-cupones"
    >
      <ModalContainer>
        <ModalTitle>
          Alertas Cupones
          <span>{data?.length || 0}</span>
        </ModalTitle>
        <AlertsContainer>
          {data && data.map((alert) => {
            const statusInfo = getStatusInfo(alert.end_date);
            return (
              <AlertCard key={`alert-id-${alert.id}`}>
                <p><span className="label">ID del Cupón:</span> {alert.id}</p>
                <p><span className="label">Vencimiento:</span> {alert.end_date}</p>
                <p className="title">{alert.title}</p>
                <StatusBadge status={statusInfo.status}>
                  {statusInfo.message}
                </StatusBadge>
              </AlertCard>
            );
          })}
          {data && data.length === 0 && (
            <EmptyMessage>
              No hay alertas que mostrar.
            </EmptyMessage>
          )}
        </AlertsContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ModalAlert;
